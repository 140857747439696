@import 'font-awesome/scss/font-awesome';

.rtpd-form-formgroup-displayed {
    margin-bottom: var(--eui-base-spacing-s) !important;
  .eui-input-group {
    margin-bottom: 0px !important;
  }
}

// TODO to be checked
.eui-page-header__label {
  border-bottom: 2px solid #bdbdbd;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}

label {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.eui-button:not(.eui-button--icon-only) {
  min-width: 7em !important;
}
.eui-button--basic.eui-button--secondary:hover {
  background-color: var(--eui-color-grey-lightest) !important;
}
.eui-button .eui-button__container .fa {
  padding-right: 0px !important;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
    border: none !important;
}

.eui-flag-icon-rounded,
.eui-flag-icon--rounded,
.eui-flag-icon-squared {
  line-height: 1.5em !important;
  width: 1.5em !important;
  height: 1.5em !important;
}

.w-20 {
  width: 20% !important;
}

input,
select,
textarea {
  color: var(--eui-color-grey-darker) !important;
}
.ux-form-group {
  margin-bottom: 0px !important;
}

.rtpd-view-mode {
  .ux-form-group__input-content {
    input {
      padding: 0px !important;
      height: 24px !important;
    }
    .mat-chip-list-wrapper input.mat-input-element,
    .mat-chip-list-wrapper .mat-standard-chip {
      display: none !important;
    }
  }
}
.eui-autocomplete__container .eui-autocomplete__field-wrapper input[readonly],
.eui-autocomplete__container .eui-autocomplete__field-wrapper input[readonly]:focus {
  height: 24px !important;
}
.rtpd-declarations-view-mode .eui-autocomplete__wrapper--inside,
.rtpd-view-mode .eui-autocomplete__wrapper--inside {
  background-color: #fafafa00 !important;
  border: none !important;
  padding-left: 0px !important;
}

.ux-form-group--compact.row.ux-form-group .ux-form-group--read-only {
  padding: 0px !important;
}

.ux-datepicker input {
    height: calc(var(--eui-base-spacing-2xl) + 2px);
}

.ux-fieldset {
  .ux-fieldset__header-label {
    font-size: 1.1rem !important;
    margin-top: var(--eui-base-spacing-s);
  }

  .ux-datepicker__input-wrapper .ux-datepicker--read-only {
    height: 24px !important;
  }
}

@media screen and (max-width: 640px) {
  .ui-table .ui-table-scrollable-view {
    max-width: 100%;
    min-width: auto !important;
  }
  .ui-table .ui-table-tbody tr {
    display: flex;
    flex-direction: column;
    height: auto !important;
    td {
      display: flex;
      justify-content: center;
      white-space: normal !important;
      text-align: center;
      &.ux-u-width-10.ng-star-inserted {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.ux-form-group--read-only {
  padding: 0px !important;
}

.eui-autocomplete__wrapper--bottom .eui-autocomplete__field-wrapper {
  margin-bottom: 0px !important;
}

span.ux-badge.ux-badge--dark-grey.ux-u-width-8.ux-badge--pill.ux-badge--small {
  background-color: #616161 !important;
}

ux-wizard-steps {
  .ux-wizard-step:hover {
    cursor: default !important;
  }
}

.hideItem {
  display: none !important;
}

.showItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid gray;
  padding-top: 1.5em;
  margin: 1.5em 0;
  .form-control {
    height: 100% !important;
  }
    button:not(.eui-button--icon-only) {
        width: 7em !important;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1.5em;
    }
}

// short text ellipsed with full text displayed in hover
.rtpd-ellipsed-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    width: 90% !important;
}

.rtpd-ellipsed-text:hover + .rtpd-full-text-display {
    display: block !important;
}

.rtpd-full-text-display {
    display: none;
    font-size: 12px;
    position: absolute;
    background-color: white;
    z-index: 1;
    border: 1px solid;
    font-weight: normal;
    top: -7px;
    color: #323232;
}

// companies selector
.rtpd-account-list-companies {
  .toggle-button {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    li {
      margin: 0.5em;
      .eui-button {
        padding: 10px !important;
        width: 26em !important;
        display: flex;
        justify-content: center;
        margin: 0px !important;
        max-width: 90vw;
        overflow-x: auto;
      }
    }
  }
}

.eui-icon-ecl-external:before {
    content: none;
}

// requests component
.ui-table .ui-table-tbody > tr {
  background-color: #fafafa;
  &:hover {
    cursor: default;
    background-color: #fafafa !important;
  }
}
.ui-table .ui-table-tbody tr {
  border-bottom: none !important;
}
.ui-table-responsive .ui-table-tbody > tr {
  background-color: #eee0;
}

.uxcardtitle {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .fa-comments:hover {
    cursor: pointer;
    color: #4d9acb !important;
  }
}

.rtpd-no-hover {
  pointer-events: none;
}

.rtpd-requests-label {
  font-weight: normal;
  color: #616161;
}

.overflow-hidden {
    overflow: hidden;
}

.rtpd-requests-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px !important;
  font-size: 16px;
  font-weight: bold;
  color: #616161;
  display: flex;
  flex-wrap: nowrap;
  .rtpd-full-text-display {
    top: 25px;
    right: -158px;
  }
}

.rtpd-requests-data-outcome {
    white-space: normal;
    padding: 0px !important;
    font-size: 16px;
    font-weight: bold;
    color: #616161;
}

rtpd-request-card ux-card {
  .ux-card__header {
    padding: var(--eui-base-spacing-m) !important;
    .ux-card__header-title {
      margin: 0px;
    }
  }

  .label, ul {
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

rtpd-requests-details {
  display: flex;
  align-items: flex-start;
  flex: 1;
    ul li {
        margin-bottom: 1rem;
    }
}

rtpd-requests-details, rtpd-requests-summary {
    ul {
        list-style: none;
        min-width: 50%;
        max-width: 50%;

        li {
            display: grid;
            grid-template-columns: 1fr 2fr ;
            gap: 10px;

            .rtpd-requests-label{
                white-space: normal;
                width: 100%;
                padding-right: 1rem !important;
            }
            .rtpd-requests-data {
                padding-right: 0.5rem;
            }

        }
    }
}

  rtp-requests-list-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    max-width: 20%;
    .eui-icon .eui-badge {
      top: -70%;
    }
  }

  rtpd-requests-summary {
      display: flex !important;
      justify-content: space-between;
      gap: 30px;
    a {
      color: var(--eui-color-link);
    }
    .column {
      &-first {
        display: flex;
        width: 100%;
          ul {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
        .rtpd-ellipsed-text {
          display: block !important;
        }
        .rtpd-full-text-display {
          top: 42px;
          left: 129px;
        }
      }
      &-second {
        white-space: normal;
        max-width: 20%;
          ul li {
              margin-bottom: 0.5rem;
          }
      }
    }
    .title {
      color: #757575;
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 992px) {
      .uxcardtitle {
            flex-direction: column;
      }
  }

  @media screen and (max-width: 768px) {
    ul,
    li,
    .uxcardtitle,
    rtpd-requests-details {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .eui-flag-icon-rounded,
      .eui-flag-icon--rounded,
      .eui-flag-icon-squared {
        line-height: var(--eui-base-spacing);
        width: 1.5em;
        height: 1.5em;
      }
    }
    .rtpd-ellipsed-text {
      white-space: break-spaces;
      text-align: center;
    }

    .rtpd-full-text-display {
      opacity: 0 !important;
    }

    .rtpd-requests-label,
    .rtpd-requests-data,
    .expandable-content ul li {
      flex-direction: column !important;
      align-items: center;
    }

    rtp-requests-list-actions {
      justify-content: center !important;
      margin-top: 1em;
    }

    rtpd-requests-summary {
      flex-direction: column;
      align-items: center;
      .column {
        &-first {
          flex-direction: column;
        }
        &-second span {
          text-align: center;
        }
      }
      .title {
        justify-content: center;
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }
}

rtpd-documents-list {
  .ux-tree-list-item {
    border-bottom: 1px solid var(--eui-color-grey-lighter);
    border-top: 0px !important;
    .ux-tree-list-item-header__content-expand-toggle {
      margin: var(--eui-base-spacing-xs) !important;
      padding: 0px !important;
    }
    ux-a-label,
    .ux-a-label__content {
      width: 100% !important;
    }
  }
  .ux-tree-list .ux-tree-list .ux-tree-list-item-header__content,
  .ux-tree-list .ux-tree-list .ux-tree-list-item-header__details-content {
    margin-left: 0px !important;
    padding: 1em 0 !important;
    padding-left: var(--eui-base-spacing) !important;
    height: auto !important;
    background-color: #f6f7fa;
  }
  .ux-tree-list-item-header__content {
    padding: 0 !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
    align-items: flex-start !important;
  }
  .ux-tree-list-item-header__content-right-content {
    margin: 0px !important;
    span.ux-icon.ux-icon-angle-right {
      color: var(--eui-color-link) !important;
    }
  }
  .ux-tree-list-item-header {
    border: none !important;
  }
  li {
    display: block !important;
    .rtpd-requests-data {
      display: block !important;
    }
  }
  .rtpd-requests-file-informations {
    width: 100% !important;
    ul {
      padding-left: 0px !important;
      li {
        display: flex !important;
        .rtpd-requests-data {
          width: auto !important;
        }
      }
      .rtpd-requests-warning-text {
        color: var(--eui-color-warning);
        font-size: small;
        font-weight: 600;
      }
    }
    @media screen and (max-width: 768px) {
      ul,
      li {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .eui-flag-icon-rounded,
        .eui-flag-icon--rounded,
        .eui-flag-icon-squared {
          line-height: var(--eui-base-spacing);
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }
}

rtpd-requested-document-type-list {
  .uxcardtitle {
    flex-direction: column;
    .header {
      &-card {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    eui-block-content {
      width: 100%;
    }
    em.fa.fa-file-text.fa-lg.ux-u-color-info {
      margin-right: 5px !important;
    }
  }
  .eui-alert {
    margin: 3rem 0px !important;
  }
  .ux-card__body {
    padding: 0px !important;
  }
  ul {
    max-width: 100% !important;
    padding: 0px;
  }
  .header-card {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    rtpd-requested-documents-actions {
      margin: 1em 0px !important;
    }
    .uxcardtitle span.mr-3 {
      margin-right: 0px !important;
    }
    .header-card {
      justify-content: center;
      flex-direction: column;
    }
  }
}

// multiplier-text-component
.rtpd-multiplier-text {
  .col-form-label {
    height: 42px !important;
    display: flex !important;
  }
  .ux-form-group {
    align-items: flex-start !important;
  }
  .rtpd-addon-input {
    display: flex;
    eui-icon {
      position: absolute !important;
      right: 12px !important;
      display: flex !important;
      align-items: center !important;
      cursor: pointer !important;
      height: 42px;
      &:hover,
      &:focus {
        color: var(--eui-color-danger-lightest) !important;
      }
    }
  }
  .rtpd-addon-button {
    width: 100%;
  }
}

// messages requests
.rtpd-requests-messages {
    .row {
        width: 500px;
    }

    div.message-form {
        margin-top: 5em;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 768px) {
            width: 70%;
        }
        .rtpd-requests-messages-select-language {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ux-form-group {
                flex: 1;

                label {
                    max-width: 200px;
                    white-space: nowrap;
                }
            }
        }
        .flex-container {
            flex-direction: column !important;
            textarea {
                min-height: 6em;
            }
        }
    }

    .rtpd-requests-messages-thread {
        .page-sub-title {
            display: none !important;
        }
        .ux-icon-android-more-horizontal::before,
        .eui-icon-more-horizontal::before,
        .ux-icon-more-horizontal::before {
            content: "\f0e5" !important;
            font-family: "FontAwesome";
        }
        .rtpd-requests-messages-icon-user {
            .ux-icon-android-more-horizontal::before,
            .eui-icon-more-horizontal::before,
            .ux-icon-more-horizontal::before {
                content: "\f007" !important;
            }
            &.user-border {
                .ux-discussion-thread__item .ux-discussion-thread-panel {
                    border: 1px solid var(--eui-color-primary);
                }
                .ux-discussion-thread__item .ux-discussion-thread-panel::before, .ux-discussion-thread__item .ux-discussion-thread-panel::after  {
                    border-bottom: 15px solid transparent;
                    border-left: 15px solid var(--eui-color-primary) !important;
                    border-right: 0 solid var(--eui-color-primary) !important;
                    left: -16px!important;
                    transform: rotate(
                            180deg)!important;
                }
            }
        }
        .rtpd-requests-messages-icon-cog {
            .ux-icon-android-more-horizontal::before,
            .eui-icon-more-horizontal::before,
            .ux-icon-more-horizontal::before {
                content: "\f013" !important;
            }
        }
        .rtpd-requests-messages-icon-flag {
            .ux-icon-android-more-horizontal::before,
            .eui-icon-more-horizontal::before,
            .ux-icon-more-horizontal::before {
                content: "\f024 " !important;
            }
        }
        .rtpd-requests-messages-show-language {
            position: relative;
            .rtpd-requests-messages-show-language-button {
                position: absolute !important;
                bottom: 15px !important;
                width: 44%;
                display: flex;
                justify-content: end;
                padding-right: 15px;
            }
            .rtpd-requests-messages-content {
                margin-bottom: 5em !important;
            }
        }
        .rtpd-requests-messages-content {
            max-height: 233px !important;
            overflow-y: auto;
            margin-top: 12px !important;
            font-size: 16px;
        }

        .rtpd-message-subtitle {
            font-size: 14px;
            color: var(--eui-color-grey-light);
            color: var(--eui-color-grey-dark);
            margin-bottom: 1em !important;
        }

        .rtpd-requests-messages-button-show-more {
            width: 100%;
            display: flex;
            justify-content: center;

            button,
            .eui-button--rounded:active,
            .eui-button--rounded:focus {
                width: 70px;
                height: 70px;
            }
        }
    }
}

// AppShell
.eui-app-main-content{
    padding: 1em;
}

eui-user-profile-menu-item.eui-user-profile-menu-item {
    cursor: pointer !important;
}

.eui-menu-item .eui-icon {
    font-family: "FontAwesome", Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: 23px !important;
}

.eui-app-sidebar-body {
    background-color: #004494 !important;
    .eui-menu-item__link-icon,
    .eui-menu-item__label,
    .eui-button--basic.eui-button--secondary {
        color: #ffff !important;
    }
    .eui-menu-item__link {
        border-top: 1px solid #4576b1 !important;
    }
    .eui-menu-item__link--active:not(.eui-menu-item--disabled),
    .eui-menu-item__link:active:not(.eui-menu-item--disabled),
    .eui-menu-item__link:hover:not(.eui-menu-item--disabled) {
        background-color: #4576b1 !important;
    }
    .eui-button--basic.eui-button--secondary:hover,
    .eui-button--basic.eui-button--secondary:active,
    .eui-menu-item .eui-button.eui-menu-item__link-toggle:active,
    .eui-menu-item .eui-button.eui-menu-item__link-toggle:focus {
        background-color: #004494 !important;
    }
    .eui-menu-item__link-label {
        text-transform: capitalize !important;
    }
}

.appname-short {
    display: none !important;
    h1{
        margin-bottom: 0px!important;
    }
}
.appname {
    display: block !important;
}
.eui-app-header {
    visibility: visible!important;
}
@media screen and (max-width: 767px) {
    div#cdk-overlay-1,
    div#cdk-overlay-0 {
        width: 80vw !important;
    }
    .appname-short {
        display: block !important;
    }
    .appname, .eui-header-right-content .eui-language-selector, .eui-user-profile__infos {
        display: none!important;
    }
    .eui-app-header {
        visibility: visible!important;
        .eui-header-user-profile {
            position: absolute;
            top: 0.2em;
            right: 3em;
        }
    }
}

.eui-footer {
    justify-content: center;
}

// TODO to be reviewed & removed IMIRTPD-1280
//.ux-layout-breadcrumbs {
//    overflow: visible !important;
//    margin-top: 4px;
//}
//.ux-layout-breadcrumbs__item a {
//    color: white !important;
//}
//
//span.ux-layout-breadcrumbs__item a {
//    color: white !important;
//}
//
//
//.ux-layout-breadcrumbs__item a:hover {
//    color: white !important;
//    text-decoration: underline !important;
//}

.ui-paginator > .ui-paginator-last {
    display: none!important;
}

.ui-paginator > .ui-paginator-first {
    display: none!important;
}

.ui-paginator > .ui-paginator-prev {
    display: none!important;
}

.w-400 {
    width: 400px !important;
    margin-bottom: calc(var(--eui-base-spacing-s) / 2) !important;
}

//ui-paginator-icon pi pi-caret-left

a {
    color: #004494;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #002148;
    text-decoration: underline;
}

div.eui-page-header {
    border-bottom: 2px solid #bdbdbd;
    padding-bottom: .5em;
    margin-bottom: 1em;
}

ul, ol {
    list-style: none;
}

.button-container .eui-button {
    margin-left: var(--eui-base-spacing-s);
}

.button-margin {
    margin-left: var(--eui-base-spacing-s) !important;
}

.hidden-file-input {
    display: none;
}

.disable-border {
    border: none !important;
}

align-items-center {
    align-items: center;
}

.eui-card-header {
    border-bottom: none !important;
}

.col-md-9 {
    margin-bottom: calc(var(--eui-base-spacing-s) / 2) !important;
}

.margin-bottom-s {
    margin-bottom: calc(var(--eui-base-spacing-s) / 2) !important;
}

// Hack for : IMIRTPD-1369
.eui-breadcrumb .eui-breadcrumb-item .eui-button {
    padding: 0;
    min-width: unset !important;

    &:first-child {
        padding-left: 0.875rem;
    }
}

.vertical-space {
    margin-top: var(--eui-base-spacing-xs);
}

.rtpd-page-search-highlight {
    background-color: #ffd617;
    text-decoration: none;
}

// start: rtpd-picklist-text
.cdk-drop-list {
    display: inherit;
}

.p-picklist {
    .p-picklist-list {
        display: block;
        border-top: none;
        overflow-wrap: break-word;
        inline-size: 1rem;
        min-height: 15rem;
        max-height: 15rem;
    }

    .p-picklist-buttons > button {
        margin: 0.17rem !important;
    }
}
// end: rtpd-picklist-text

.rtpd-overflow-wrap {
    overflow-wrap: break-word;
}
